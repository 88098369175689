<template>
	<div>
		<ContentHeader title="Reseptionis" subTitle="Tambah Data Pasien" url="/resepsionis" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
		            <div class="col">
		            	<div class="card">
		            		<div class="card-header row">
		            			<h3 class="card-title">Input Data Pasien</h3>
		            		</div>
		            		<div class="card-body">
		            			<form @submit.prevent="prosesPasien">
		            				<div class="row">
		            					<div class="col">
		            							<div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nomor_rekam_medis">Nomor Rekam Medis</label>
								                    <input type="text" class="form-control" id="nomor_rekam_medis" name="nomor_rekam_medis"  disabled="" v-model="pasien.nomor_rekam_medis">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nomor_rekam_medis_lama">Nomor Rekam Medis Lama</label>
								                    <input type="text" class="form-control" id="nomor_rekam_medis_lama" name="nomor_rekam_medis_lama" v-model="pasien.nomor_rekam_medis_lama">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nama">Nama <span class="text-danger">*</span></label>
								                    <input type="text" class="form-control" id="nama" autocomplete="off" name="nama" required="" v-model="pasien.nama" @keyup="generateRekamMedis(pasien.nama)">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nomor_identitas">Nomor Identitas (KTP/SIM/Dll) <span class="text-danger">*</span></label>
								                    <input type="number" class="form-control" id="nomor_identitas" autocomplete="off" name="nomor_identitas" required="" v-model="pasien.nomor_identitas">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="">Provinsi <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" @change="getKota(pasien.provinsi)" v-model="pasien.provinsi">
			      										<option selected>Pilih Provinsi</option>
			      										<option v-for="row in provinsi" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="">Kota / Kabupaten <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" @change="getKecamatan(pasien.kota)" v-model="pasien.kota">
			      										<option selected>Pilih Kota/Kabupaten</option>
			      										<option v-for="row in kota" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="">Kecamatan <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" @change="getDesa(pasien.kecamatan)" v-model="pasien.kecamatan">
			      										<option selected>Pilih Kecamatan</option>
			      										<option v-for="row in kecamatan" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="">Desa <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" v-model="pasien.desa">
			      										<option selected>Pilih Desa</option>
			      										<option v-for="row in desa" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="alamat_lengkap">Alamat Lengkap <span class="text-danger">*</span></label>
								                    <input type="text" class="form-control" id="alamat_lengkap" autocomplete="off" name="alamat_lengkap" required="" v-model="pasien.alamat_lengkap">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nomor_whatsapp">Nomor Whatsapp <span class="text-danger">*</span></label>
								                    <input type="number" class="form-control" id="nomor_whatsapp" autocomplete="off" name="nomor_whatsapp" required="" v-model="pasien.nomor_whatsapp">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nomor_telepon">Nomor Telepon </label>
								                    <input type="number" class="form-control" id="nomor_telepon" autocomplete="off" name="nomor_telepon" v-model="pasien.nomor_telepon">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="jenis_kelamin">Jenis Kelamin <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" id="jenis_kelamin" name="jenis_kelamin" required="" v-model="pasien.jenis_kelamin">
			      										<option selected></option>
				      									<option value="L">Laki-laki</option>
				      									<option value="P">Perempuan</option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nama_anggota_keluarga">Nama Anggota Keluarga </label>
								                    <input type="text" class="form-control" id="nama_anggota_keluarga" autocomplete="off" name="nama_anggota_keluarga" v-model="pasien.nama_anggota_keluarga">
								                  </div>
								                </div>
		            					</div>
		            					<div class="col">
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="golongan_darah">Golongan Darah <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" id="golongan_darah" name="golongan_darah" required="" v-model="pasien.golongan_darah">
			      										<option selected></option>
				      									<option value="-">-</option>
				      									<option value="A">A</option>
				      									<option value="B">B</option>
				      									<option value="AB">AB</option>
				      									<option value="O">O</option>
				    								</select>
								                  </div>
								                </div>
		            							<div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="tempat_lahir">Tempat Lahir <span class="text-danger">*</span></label>
								                    <select class="custom-select form-control" v-model="pasien.tempat_lahir">
			      										<option selected>Pilih Tempat Lahir</option>
			      										<option v-for="row in kota" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="tanggal_lahir">Tanggal Lahir <span class="text-danger">*</span></label>
								                    <input type="date" class="form-control" id="tanggal_lahir" name="tanggal_lahir" required="" v-model="pasien.tanggal_lahir">
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="agama">Agama <span class="text-danger">*</span></label>
								                  	<select class="custom-select form-control" id="agama" name="agama" required="" v-model="pasien.agama">
			      										<option selected></option>
				      									<option value="Islam">Islam</option>
				      									<option value="Kristen Protestan">Kristen Protestan</option>
				      									<option value="Kristen Katolik">Kristen Katolik</option>
				      									<option value="Hindu">Hindu</option>
				      									<option value="Budda">Budda</option>
				      									<option value="Konghucu">Konghucu</option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="pendidikan">Pendidikan <span class="text-danger">*</span></label>
								                    <select class="custom-select form-control" id="pendidikan" name="pendidikan" required="" v-model="pasien.pendidikan">
			      										<option selected></option>
				      									<option value="-">-</option>
				      									<option value="SD/MI">SD/MI</option>
				      									<option value="SMP/MTs">SMP/MTs</option>
				      									<option value="SMA/SMK/MA">SMA/SMK/MA</option>
				      									<option value="D3">D3</option>
				      									<option value="S1">S1</option>
				      									<option value="S2">S2</option>
				      									<option value="S3">S3</option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="pekerjaan">Pekerjaan <span class="text-danger">*</span></label>
								                    <select class="custom-select form-control" id="pekerjaan" name="pekerjaan" required="" v-model="pasien.pekerjaan">
			      										<option selected></option>
				      									<option value="wiraswasta">Wiraswasta</option>
				      									<option value="pegawai_negeri">Pegawai Negeri</option>
				      									<option value="karyawan_swasta">Karyawan Swasta</option>
				      									<option value="profesional">Profesional</option>
				      									<option value="petani">Petani</option>
				      									<option value="guru">Guru</option>
				      									<option value="ibu_rumah_tangga">Ibu Rumah Tangga</option>
				      									<option value="pelajar">Pelajar</option>
				      									<option value="mahasiswa">Mahasiswa</option>
				      									<option value="lainnya">Lainnya</option>
				    								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="sumber_informasi">Sumber Informasi <span class="text-danger">*</span></label>
								                    <select class="custom-select form-control" id="sumber_informasi" name="sumber_informasi" required="" v-model="pasien.sumber_informasi">
			      										<option selected></option>
				      									<option value="cuma_lewat">Cuma Lewat</option>
				      									<option value="instagram">Instagram</option>
				      									<option value="klinik_kesehatan">Klinik Kesehatan</option>
				      									<option value="brosur">Brosur</option>
				      									<option value="radio">Radio</option>
				      									<option value="website">website</option>
				      									<option value="teman">Teman</option>
				      									<option value="facebook">Facebook</option>
				      									<option value="whatsapp">whatsapp</option>
				    								</select>
								                  </div>
								                </div>

								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="alergi">Alergi <span class="text-danger">*</span></label>
								                    <input class="form-control" id="alergi" name="alergi" v-model="pasien.alergi">
								                  </div>
								                  <div class="form-group">
								                    <label for="asuransi">Asuransi</label>
								                    <select class="custom-select form-control" id="asuransi" name="asuransi" v-model="pasien.asuransi">
			      										<option selected></option>
				      									<option value="BPJSKES">BPJSKES</option>
				      									<option value="BPJSTK">BPJSTK</option>
				      									<option value="PINDAD">PINDAD</option>
				      									<option value="PDAM">PDAM</option>
				      									<option value="INHEALTH">INHEALTH</option>
				      								</select>
								                  </div>
								                </div>
								                <div class="px-lg-2 ">
								                  <div class="form-group">
								                    <label for="nomor_asuransi">Nomor Asuransi </label>
								                    <input type="number" class="form-control" id="nomor_asuransi" autocomplete="off" name="nomor_asuransi" v-model="pasien.nomor_asuransi">
								                  </div>
								                </div>
												<div class="px-lg-2">
								                  <div class="form-group">
								                    <label for="status_pernikahan">Status Pernikahan</label>
								                    <select class="custom-select form-control" id="status_pernikahan" name="status_pernikahan" v-model="pasien.status_pernikahan">
				      									<option value="menikah">Menikah</option>
				      									<option value="belum menikah">Belum Menikah</option>
				      									<option value="janda">Janda</option>
				      									<option value="duda">Duda</option>
				      								</select>
								                  </div>
												</div>
		            					</div>
		            				</div>
		            				<div class="px-lg-2">
		            					<button type="submit" class="btn btn-primary d-flex align-items-center">
										  <div>
										  	Submit
										  </div>	
										  <div>
						                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						                      <g>
						                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
						                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
						                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
						                      </g>

						                    </svg>
						                  </div>
										</button>
		            				</div>
					                <!-- /.card-body -->					               
					            </form>
		            		</div>
		            	</div>
		            </div>
		        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'

	import { reactive, computed, onMounted, ref  } from 'vue'
	import Swal from 'sweetalert2'
	import axios from 'axios'
	import store from '@/store'
	import router from '@/router'

	const user   = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const loading = ref(false)

	// Generate Kode Tindakan
	const kode_cabang = ref('')
	const kode_dari_nama = ref('')
	const nomor_urut = ref('')
	const cek = ref(0)

	const getCabang = async () => {
		await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)
		.then((response) => {
			kode_cabang.value = `${response.data.kode_cabang}`
		}) 
		.catch((error) => {
			console.log(error)
		})

		pasien.nomor_rekam_medis = `${kode_cabang.value}-`
	}

	const tambah_nol = (x) => {
		let y = (x > 9) ? (x > 99) ? (x > 999) ? (x > 9999) ? x : '0' + x : '00' + x : '000' + x : '0000' + x;
		return y;
	}


	const generateRekamMedis = (nama) => {
		
		if (nama == '') {
			pasien.nomor_rekam_medis = ``
		} else {
			kode_dari_nama.value = nama.slice(0, 1).toUpperCase()

			if (kode_cabang.value.length > 0 && kode_dari_nama.value != '') {
				axios.get(`api/pasien/getForGenerateCode/${kode_cabang.value}-${kode_dari_nama.value}/${cabang.value.tipe}`)
				.then((response) => {
					console.log(response.data)
					if(response.data == 'kosong'){
						cek.value = 1
						nomor_urut.value = tambah_nol(1)
						generateKode()
					} else {
						cek.value = 1
						let str = response.data
						let length = str.length
						let nlength = length - 5
						let id = str.slice(nlength, length) 
						id = Math.abs(id)
						nomor_urut.value = tambah_nol(id + 1)
						generateKode()
					}
				})
				.catch((error) => {
					console.log(error)
				})
			}
		}
	}

	const generateKode = () => {
		pasien.nomor_rekam_medis = `${kode_cabang.value}-${kode_dari_nama.value}${nomor_urut.value}`
	}

	// End

	const pasien = reactive({
		"cabang_id": user.value.cabang_id,
		"nomor_rekam_medis": '',
		"nama": "",
		"nomor_identitas": "",
		"provinsi": null,
		"kota": null,
		"kecamatan": null,
		"desa": null,
		"alamat_lengkap": "",
		"nomor_telepon": "",
		"nomor_whatsapp": "",
		"jenis_kelamin": "",
		"golongan_darah": "",
		"tempat_lahir": "",
		"tanggal_lahir": "",
		"agama": "",
		"pekerjaan": "",
		"pendidikan": "",
		"sumber_informasi": "",
		"sosial_media": "",
		"nomor_member": "",
		"label": "",
		"foto": "",
		"status_member": false,
		"nomor_rekam_medis_lama": null,
		"asuransi": null,
		"nomor_asuransi": null,
		"nama_anggota_keluarga": null,
		"status_pernikahan": null,
		"alergi": null,
	})

	const resetDataPasien = () => {
		pasien.nama =  '',
		pasien.nomor_identitas = '',
		pasien.alamat_lengkap = '',
		pasien.nomor_telepon = '',
		pasien.nomor_whatsapp = '',
		pasien.jenis_kelamin = '',
		pasien.golongan_darah = '',
		pasien.tempat_lahir = '',
		pasien.tanggal_lahir = '',
		pasien.agama = '',
		pasien.pekerjaan = '',
		pasien.pendidikan = '',
		pasien.sumber_informasi = '',
		pasien.sosial_media = '',
		pasien.nomor_member = '',
		pasien.label = '',
		pasien.foto = '',
		pasien.status_member = false,
		pasien.asuransi = null,
		pasien.nomor_asuransi = null,
		pasien.nama_anggota_keluarga = null
		pasien.status_pernikahan = null
		pasien.alergi = null
	}

	const prosesPasien = () => {
		loading.value = true
		axios.post(`api/pasien/create/${cabang.value.tipe}`, pasien)
		.then((response) => {
			Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menambahkan Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
			})
			loading.value = false
			resetDataPasien()
			console.log(response)
			router.push('/resepsionis')
		})
		.catch((error) => {
			Swal.fire({
			title: 'Gagal!',
			text: 'Gagal Menambahkan data',
			icon: 'error',
			showConfirmButton: false,
			timer: 1500
			})	
			loading.value = false
			console.log(error)
		})
	}

	// Get Provinsi
	const provinsi = ref([])

	const getProvinsi = async () => {
		let response = await axios.get('api/wilayah/provinsi')

		provinsi.value = response.data
	}

	// End Get Provinsi

	// Get Kota
	const kota = ref([])

	const getKota = async (kode) => {
		let response = await axios.get(`api/wilayah/kota/${kode}`)
		kota.value = response.data
	}
	// End Get Kota

	// Get kecamatan
	const kecamatan = ref([])

	const getKecamatan = async (kode) => {
		let response = await axios.get(`api/wilayah/kecamatan/${kode}`)
		kecamatan.value = response.data
	}
	// End Get kecamatan

	// Get desa
	const desa = ref([])

	const getDesa = async (kode) => {
		let response = await axios.get(`api/wilayah/desa/${kode}`)
		desa.value = response.data
	}
	// End Get desa

	onMounted(() => {		    	
		getProvinsi()
		getCabang()
	})
</script>

<style>
	.hijau{
		background-color: #10B981;
	}

	.kuning{
		background-color: #FCD34D;
	}

	.merah{
		background-color: #EF4444;
	}

	.hitam{
		background-color: #1F2937;
	}

	.ungu{
		background-color: #8B5CF6;
	}

	.biru{
		background-color: #3B82F6;
	}


</style>