<template>
	<TambahDataPasienKecantikan v-if="cabang.tipe == 'kecantikan'" />
	<TambahDataPasienKesehatan v-else />
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'
import TambahDataPasienKecantikan from './components/TambahDataPasienKecantikan.vue'
import TambahDataPasienKesehatan from './components/TambahDataPasienKesehatan.vue'

const cabang = computed(() => store.getters['auth/cabang'])
</script>
